.home {
  p {
    text-align: center;
  }
}

.post.camp {
  table.reservation-table {
    tr {
      td:first-child {
        width: 1%;
        white-space: nowrap;
      }

      td > ul {
        margin-bottom: 0;
      }
    }
  }
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
  grid-gap: 0.5em;
  margin-bottom: 2em;

  .thumbnail {
    position: relative;
    width: 6em;
    height: 6em;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.season .camps-list-dates {
  font-size: 0.7em;
}

.camps-list-dates {
  color: gray;
}

.gallery {
  margin-bottom: 3em;
}


.embeddable-buttondown-form {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 1px solid #ccc;
  padding: 2em;

  label {
    display: block;
    margin-bottom: 1em;
  }

  input[type=email] {
    padding: 0.75em 1em;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1em;
  }

  input[type=submit] {
    padding: 0.75em 1em;
    display: block;
    width: 100%;
    background: #FBD58F;
    border-radius: 0.25em;
    color: #191716;
    outline: 0;
    border: 0;
    font-weight: bold;
    font-family: Arial;
  }
}

hr {
  margin-top: 2em;
  margin-bottom: 2em;
}


.post-content {
  h2 {
    margin-top: 1em;
    line-height: 1.2em;
  }

}

.post-content, .page-content {
  .block-image {
    &+p > img {
      display: block;
      max-height: 400px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2em;

      &+em {
        display: block;
        text-align: center;
        margin-top: 0.3em;
      }
    }
  }
}

.big-button-container {
  text-align: center;

  .big-button {
    display: inline-block;
    padding: 1em;

    a {
      background-color: #abdafc; 
      border-radius: 10px;
      color: #B8336A;
      padding: 1em;
      text-decoration: none;
      text-align: center;
    }
  }
}

.pink-button {
  padding: 0.5em 1em;
  background-color: #abdafc; 
  color: #B8336A;
  display: inline-block;
}

.post-nav {
  margin-top: 1em;
  margin-bottom: 2em;

  .previous-next {
    display: flex;
    flex-wrap: wrap;

    .previous, .next {
      flex: 50%;
    }
    .next {
      text-align: right;
    }
 
    @media only screen and (max-width : 768px) {
      .previous, .next {
        flex: 100%;
        text-align: center;
      }
    }
 }
}

.camp-home-year {
  margin-top: 2em;
  margin-bottom: 5em;
}

.latest-cooling {
  margin-bottom: 2em;
}

.attendees-join {
  margin: 1em;
}

.full-capsule {
  display: inline-block;
  padding: 0.2em 0.6em;
  border-radius: 20px;
  background-color: #a85fff;

  a {
    text-decoration: none;
    color: #fff;
  }
}

.camp-post-title {
  display: flex;
  align-items: center;
  gap: 0.2em;
}
